<script lang="ts">
  export let variant:
    | "default"
    | "primary"
    | "error"
    | "warning"
    | "accent-pink"
    | "accent-blue" = "default";

  export let ellipsis = true;
  export let onClick: (() => void) | undefined = undefined;
</script>

<svelte:element
  this={onClick ? "button" : "span"}
  class="inline-block py-0.5 px-2 text-xs font-normal rounded-full whitespace-nowrap"
  class:overflow-hidden={ellipsis}
  class:text-ellipsis={ellipsis}
  class:chip-default={variant === "default"}
  class:chip-primary={variant === "primary"}
  class:chip-error={variant === "error"}
  class:chip-warning={variant === "warning"}
  class:chip-accent-pink={variant === "accent-pink"}
  class:chip-accent-blue={variant === "accent-blue"}
  class:clickable={!!onClick}
  on:click={onClick}
>
  <slot />
</svelte:element>

<style lang="postcss">
  .chip-default {
    @apply bg-zinc-100/[15%] text-zinc-300;
  }

  .chip-primary {
    @apply bg-primary/[15%] text-primary;
  }
  .chip-error {
    @apply bg-error/[15%] text-error;
  }
  .chip-warning {
    @apply bg-warning/[15%] text-warning;
  }

  /* TODO: we hardcoded accent-pink and accent-blue values here to match designs, but we'll need
  to reconcile with the values in tailwind.config.js
  */
  .chip-accent-pink {
    background: rgba(255, 14, 202, 0.2);
    color: #ff8de6;
  }
  .chip-accent-blue {
    background: rgba(16, 143, 235, 0.4);
    color: #91c8ef;
  }

  .clickable {
    cursor: pointer;
  }
</style>
